var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ts-panel',[_c('ts-panel-wrapper',[_c('h4',{staticClass:"tw-mb-0"},[_vm._v(_vm._s(_vm.$t("user.allowIp")))]),_c('div',{staticClass:"tw-mt-2 tw-border tw-border-gray-200 tw-rounded-lg tw-h-40 tw-overflow-auto"},[_c('div',{staticClass:"tw-rounded-sm tw-p-2"},[_c('div',{staticClass:"tw-flex tw-justify-between tw-border-b tw-border-gray-200 tw-pb-1"},[_c('p',{staticClass:"tw-font-bold tw-mb-0"},[_c('small',{staticClass:"tw-text-gray-400"},[_vm._v(_vm._s(_vm.$t("user.allowAccessSpecifyIpAddress")))])]),_c('a',{staticClass:"hover:tw-no-underline tw-text-right",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.addIp.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-plus"})])]),_c('div',{staticClass:"tw-grid xl:tw-grid-cols-4 xl:tw-gap-2 tw-gap-2 tw-mt-2"},[_vm._l((_vm.model.allow_ip_address),function(item,index){return [_c('div',{key:index,staticClass:"tw-col-span-3"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(item.ip),expression:"item.ip",modifiers:{"trim":true}}],staticClass:"tw-border-b tw-border-gray-400 tw-w-full tw-outline-none tw-px-3",class:{
                                    'is-invalid': _vm.errors.has(
                                        'allow_ip_address.' + index + '.ip'
                                    )
                                },attrs:{"type":"text","placeholder":_vm.$t('user.ip')},domProps:{"value":(item.ip)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "ip", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                                    _vm.errors.has(
                                        'allow_ip_address.' + index + '.ip'
                                    )
                                )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first( "allow_ip_address." + index + ".ip" ))+" ")]):_vm._e()]),_c('a',{key:'a'+index,staticClass:"tw-text-right tw-my-auto tw-text-red-600 hover:tw-text-red-600",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.removeIp(index)}}},[_c('i',{staticClass:"fa fa-trash"})])]})],2)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }