<template>
    <ts-panel>
        <ts-panel-wrapper>
            <h1 class="tw-mb-0">{{ $t('user.account') }}</h1>
            <small class="tw-text-gray-400">{{
                $t('user.createANewUserToOperateInSystem')
            }}</small>
            <div class="mb-3 tw-mt-3">
                <label class="mb-1 required">{{
                    $t('user.employeeName')
                }}</label>
                <Select
                    v-model="model.employee_id"
                    filterable
                    :remote-method="loadEmployee"
                    :loading="employeeLoading"
                    :placeholder="$t('user.searchEmployeeName')"
                    :class="{ 'is-invalid': errors.has('employee_id') }"
                    :clearable="true"
                    @on-change="onChangeEmployee"
                >
                    <Option
                        v-for="(option, index) in employees"
                        :value="option.employee_id"
                        :key="index"
                        >{{ option.employee_name_en }} |
                        {{ $t('employee.branch') }}:
                        {{ option.branch ? option.branch.branch_name_en : '' }}
                    </Option>
                </Select>
                <div class="invalid-feedback" v-if="errors.has('employee_id')">
                    {{ errors.first('employee_id') }}
                </div>
            </div>
            <div class="mb-3">
                <label class="mb-1 required">{{ $t('user.userName') }}</label>
                <input
                    v-model.trim="model.user_name"
                    type="text"
                    class="form-control"
                    :placeholder="$t('user.userName')"
                    :class="{ 'is-invalid': errors.has('user_name') }"
                    :disabled="isEdit"
                    ref="user_name"
                />
                <div class="invalid-feedback" v-if="errors.has('user_name')">
                    {{ errors.first('user_name') }}
                </div>
            </div>
            <div class="mb-3">
                <label class="mb-1">{{ $t('user.email') }}</label>
                <input
                    v-model.trim="model.email"
                    type="email"
                    class="form-control"
                    :placeholder="$t('user.emailAddress')"
                    :class="{ 'is-invalid': errors.has('email') }"
                />
                <div class="invalid-feedback" v-if="errors.has('email')">
                    {{ errors.first('email') }}
                </div>
            </div>
            <div v-if="!isEdit">
                <label class="mb-1 required">{{ $t('user.password') }}</label>
                <div class="row gx-3">
                    <div class="col-md-6 mb-1 mb-md-0">
                        <input
                            v-model="model.password"
                            type="password"
                            class="form-control fs-13px"
                            :placeholder="$t('user.password')"
                            :class="{ 'is-invalid': errors.has('password') }"
                        />
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('password')"
                        >
                            {{ errors.first('password') }}
                        </div>
                    </div>
                    <div class="col-md-6 mb-1 mb-md-0">
                        <input
                            v-model="model.password_confirmation"
                            type="password"
                            class="form-control fs-13px"
                            :placeholder="$t('user.confirm')"
                            :class="{
                                'is-invalid': errors.has(
                                    'password_confirmation'
                                )
                            }"
                        />
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('password_confirmation')"
                        >
                            {{ errors.first('password_confirmation') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-3">
                <ts-checkbox v-model="model.disable">{{
                    $t('user.disable')
                }}</ts-checkbox>
            </div>
        </ts-panel-wrapper>
    </ts-panel>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { isEmpty, debounce } from 'lodash'
import { mapActions, mapState } from 'vuex'

export default {
    name: 'userAccount',
    props: ['value', 'validate'],
    data () {
        return {
            errors: new Errors(),
            employeeLoading: false,
            model: {}
        }
    },
    computed: {
        ...mapState('auth/user', ['edit_data']),
        isEdit () {
            return !isEmpty(this.edit_data)
        },
        employees () {
            return this.$store.state.auth.user.employees
        }
    },
    mounted () {
        this.$refs.user_name.focus()
    },
    created () {
        this.model = this.value
    },
    methods: {
        ...mapActions('auth/user', ['getEmployees']),
        // loadEmployee (query) {
        //     if (query !== '') {
        //         this.employeeLoading = true
        //         setTimeout(() => {
        //             this.getEmployees({ search: query }).finally(() => {
        //                 this.employeeLoading = false
        //             })
        //         }, 500)
        //     }
        // },
        loadEmployee: debounce(function (query) {
            this.getEmployees({ search: query })
        }, 1000),
        onChangeEmployee () {
            if (this.model.employee_id) {
                let employee = this.employees.find(
                    p => p.employee_id == this.model.employee_id
                )
                this.model.user_name = employee.employee_name_en
                    .split(' ')
                    .join('.')
                    .toLowerCase()
            } else {
                this.model.user_name = ''
            }
            this.$nextTick(function () {
                this.$refs.user_name.focus()
            })
        }
    },
    watch: {
        validate: function (error) {
            this.errors = new Errors(error.errors)
        }
    }
}
</script>
