var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-panel',[_c('ts-panel-wrapper',[_c('div',{staticClass:"tw-flex tw-justify-between"},[_c('div',{staticClass:"tw-justify-start"},[_c('h4',{staticClass:"tw-mb-0"},[_vm._v(_vm._s(_vm.$t("user.assignBranches")))]),_c('small',{staticClass:"tw-text-gray-400"},[_vm._v(_vm._s(_vm.$t("user.defineBranchesAndDefaultBranchForUser")))]),_c('br'),_c('ts-checkbox',{staticClass:"tw-m-1",on:{"change":_vm.setAllBranches},model:{value:(_vm.checkedAll),callback:function ($$v) {_vm.checkedAll=$$v},expression:"checkedAll"}},[_vm._v(_vm._s(_vm.$t("addAll")))])],1),_c('div',{staticClass:"tw-justify-end"},[(_vm.errors.has('branches'))?_c('div',{staticClass:"tw-text-red-600"},[_vm._v(" "+_vm._s(_vm.errors.first("branches"))+" ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","placeholder":_vm.$t('search')},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})])]),_c('ul',{staticClass:"tw-mt-2 tw-border tw-border-gray-200 tw-rounded-lg tw-h-36 tw-overflow-auto"},_vm._l((_vm.branches),function(branch,index){return _c('li',{key:index},[_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between"},[_c('ts-checkbox',{staticClass:"tw-m-1",attrs:{"value":("" + (branch.branch_id)),"checkedValue":Object.keys(_vm.model.branches)},on:{"change":_vm.setBranches}},[_vm._v(_vm._s(branch.branch_name_en))]),(
                                Object.keys(_vm.model.branches).includes(
                                    ("" + (branch.branch_id))
                                )
                            )?_c('i-switch',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('user.isDefault')),expression:"$t('user.isDefault')"}],attrs:{"size":"small"},on:{"on-change":function (event) { return _vm.setAsDefaultBranch(
                                        branch.branch_id,
                                        event
                                    ); }},model:{value:(
                                _vm.model.branches[branch.branch_id].is_default
                            ),callback:function ($$v) {_vm.$set(_vm.model.branches[branch.branch_id], "is_default", $$v)},expression:"\n                                model.branches[branch.branch_id].is_default\n                            "}}):_vm._e()],1)])}),0)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }