<template>
    <ts-panel>
        <ts-panel-wrapper>
            <h4 class="tw-mb-0">{{ $t("user.allowIp") }}</h4>
            <div class="tw-mt-2 tw-border tw-border-gray-200 tw-rounded-lg tw-h-40 tw-overflow-auto">
                <div class="tw-rounded-sm tw-p-2">
                    <div
                        class="tw-flex tw-justify-between tw-border-b tw-border-gray-200 tw-pb-1"
                    >
                        <p class="tw-font-bold tw-mb-0">
                            <small class="tw-text-gray-400">{{
                                $t("user.allowAccessSpecifyIpAddress")
                            }}</small>
                        </p>
                        <a
                            href="#"
                            @click.prevent="addIp"
                            class="hover:tw-no-underline tw-text-right"
                        >
                            <i class="fa fa-plus"></i>
                        </a>
                    </div>
                    <div
                        class="tw-grid xl:tw-grid-cols-4 xl:tw-gap-2 tw-gap-2 tw-mt-2"
                    >
                        <template
                            v-for="(item, index) in model.allow_ip_address"
                        >
                            <div class="tw-col-span-3" :key="index">
                                <input
                                    v-model.trim="item.ip"
                                    type="text"
                                    class="tw-border-b tw-border-gray-400 tw-w-full tw-outline-none tw-px-3"
                                    :placeholder="$t('user.ip')"
                                    :class="{
                                        'is-invalid': errors.has(
                                            'allow_ip_address.' + index + '.ip'
                                        )
                                    }"
                                />
                                <div
                                    class="invalid-feedback"
                                    v-if="
                                        errors.has(
                                            'allow_ip_address.' + index + '.ip'
                                        )
                                    "
                                >
                                    {{
                                        errors.first(
                                            "allow_ip_address." + index + ".ip"
                                        )
                                    }}
                                </div>
                            </div>
                            <a
                                href="#"
                                :key="'a'+index"
                                class="tw-text-right tw-my-auto tw-text-red-600 hover:tw-text-red-600"
                                @click.prevent="removeIp(index)"
                            >
                                <i class="fa fa-trash"></i>
                            </a>
                        </template>
                    </div>
                </div>
            </div>
        </ts-panel-wrapper>
    </ts-panel>
</template>

<script>
import { Errors } from "form-backend-validation";

export default {
    name: "allowIp",
    props: ["value", "validate"],
    data() {
        return {
            model: {},
            errors: new Errors()
        };
    },
    computed: {
        roles() {
            return this.$store.state.auth.user.userRole.map(el => ({
                label: el.name,
                value: el.id
            }));
        }
    },
    created() {
        this.model = this.value;
    },
    methods: {
        addIp() {
            this.model.allow_ip_address.push({
                ip: null
            });
        },
        removeIp(index) {
            this.model.allow_ip_address.splice(index, 1);
        }
    },
    watch: {
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>
