<template>
    <div>
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between">
                    <div class="tw-justify-start">
                        <h4 class="tw-mb-0">{{ $t("user.assignBranches") }}</h4>
                        <small class="tw-text-gray-400">{{
                            $t("user.defineBranchesAndDefaultBranchForUser")
                        }}</small>
                        <br />
                        <ts-checkbox
                            class="tw-m-1"
                            @change="setAllBranches"
                            v-model="checkedAll"
                            >{{ $t("addAll") }}</ts-checkbox
                        >
                    </div>
                    <div class="tw-justify-end">
                        <div
                            class="tw-text-red-600"
                            v-if="errors.has('branches')"
                        >
                            {{ errors.first("branches") }}
                        </div>
                        <input
                            v-model="search"
                            type="text"
                            class="form-control form-control-sm"
                            :placeholder="$t('search')"
                        />
                    </div>
                </div>
                <ul
                    class="tw-mt-2 tw-border tw-border-gray-200 tw-rounded-lg tw-h-36 tw-overflow-auto"
                >
                    <li v-for="(branch, index) in branches" :key="index">
                        <div class="tw-flex tw-items-center tw-justify-between">
                            <ts-checkbox
                                class="tw-m-1"
                                @change="setBranches"
                                :value="`${branch.branch_id}`"
                                :checkedValue="Object.keys(model.branches)"
                                >{{ branch.branch_name_en }}</ts-checkbox
                            >
                            <i-switch
                                v-tooltip="$t('user.isDefault')"
                                size="small"
                                v-if="
                                    Object.keys(model.branches).includes(
                                        `${branch.branch_id}`
                                    )
                                "
                                v-model="
                                    model.branches[branch.branch_id].is_default
                                "
                                @on-change="
                                    event =>
                                        setAsDefaultBranch(
                                            branch.branch_id,
                                            event
                                        )
                                "
                            >
                            </i-switch>
                        </div>
                    </li>
                </ul>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>
<script>
import { Errors } from "form-backend-validation";
import { transform } from "lodash";

export default {
    name: "branchList",
    props: ["value", "validate"],
    data() {
        return {
            errors: new Errors(),
            model: {},
            checkedAll: false,
            search: null
        };
    },
    computed: {
        branches() {
            if (this.search) {
                return this.$store.state.auth.user.branches.filter(
                    p =>
                        p.branch_name_en
                            .toLowerCase()
                            .indexOf(this.search.toLowerCase()) > -1
                );
            }
            return this.$store.state.auth.user.branches;
        }
    },
    created() {
        this.model = this.value;
    },
    methods: {
        setBranches(branches) {
            this.model.branches = transform(
                branches,
                (result, branchId) => {
                    result[branchId] = {
                        is_default: this.model.branches[branchId]
                            ? this.model.branches[branchId].is_default
                            : false
                    };
                },
                {}
            );
        },
        setAsDefaultBranch(branchId, value) {
            Object.keys(this.model.branches)
                .filter(bKey => bKey != branchId)
                .forEach(bKey => {
                    this.$set(this.model.branches[bKey], "is_default", false);
                });

            this.$set(this.model.branches[branchId], "is_default", value);
            this.$forceUpdate();
        },
        setAllBranches() {
            if (this.checkedAll) {
                this.model.branches = transform(
                    this.$store.state.auth.user.branches.map(
                        el => el.branch_id
                    ),
                    (result, branchId) => {
                        result[branchId] = {
                            is_default: this.model.branches[branchId]
                                ? this.model.branches[branchId].is_default
                                : false
                        };
                    },
                    {}
                );
            } else {
                this.model.branches = [];
            }
        }
    },
    watch: {
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>
<style scoped>
ol,
ul {
    padding-left: 2px;
}
</style>
